<!-- src/components/MyAccount.vue -->

<template src="@/../../../templates/brand/components/templates/user/MyAccount.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState } from 'vuex'
import auth from '@/util/auth'
import aclrules from '@/../../../templates/site/UI/app/acl-rules.js'

export default {
  name: 'MyAccount',
  created () {
    this.getLocations()
  },
  data () {
    return {
      oldPass: '',
      newPass: '',
      confirmPass: '',
      oldPassInvalid: true,
      editPass: false
    }
  },
  computed: {
    ...mapState({
      user: ({ App }) => App.user
    }),
    passCanChange: function () {
      return (
        !this.oldPassInvalid &&
        this.oldPass.length &&
        this.newPass.length &&
        this.newPass === this.confirmPass
      )
    },
    myAccountHome: function () {
      return this.$route.path === '/myaccount'
    },
    cartIsEmpty: function () {
      return !_.keys(this.user.profile.cart.items).length
    },
    preorderIsEmpty: function () {
      return !_.keys(this.user.profile.preorders.items).length
    },
    acl () {
      return aclrules
    },
    paymentMethods () {
      if (!this.acl || !this.acl['payment.methods'] || !this.user.role.level) return []
      return this.acl['payment.methods'](this.user.role.level)
    }
  },
  methods: {
    ...mapActions(['getLocations', 'logout']),
    changePass: function () {
      if (!this.oldPassInvalid && this.newPass == this.confirmPass) {
        this.$http
          .post('/users/changepass', { from: this.oldPass, to: this.newPass })
          .then(() => {
            this.logout()
          })
      }
    },
    testOldPass: function (evt) {
      console.info('evt', evt.target.value)
      // this.oldPass = evt.target.value;
      auth.testPass(this, this.oldPass).then(res => {
        return (this.oldPassInvalid = res.pass)
      })
    }
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/user/MyAccount.css"></style>
